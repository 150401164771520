<template>
    <div class="page">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('我的消息')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="page-main">
            <van-pull-refresh
                    :loosing-text="$t('释放即可刷新')"
                    :pulling-text="$t('下拉即可刷新')"
                    :loading-text="$t('加载中')"
                    v-model="refreshing" @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :loading-text="$t('加载中')"
                        :finished-text="$t('没有更多了')"
                        @load="onLoad"
                >
                    <div class="bs-panel wt-card" v-for="(item, index) in list" :key="index"
                         @click="toDetail(item)">
                        <div class="msg-body flex-center">
                            <div class="flex_bd">
                                <div class="msg-title">
                                   <div class="msg-icon">
                                        <van-icon color="#3270e0" v-if="item.mark == 0" name="bell" size="18" dot />
                                        <van-icon color="#3270e0" v-else name="bell" size="18" />
                                   </div>
                                    {{item.title}}
                                </div>
                            </div>
                        </div>
                        <div class="msg-foot text-gray">{{item.created_at}}</div>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>

    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                active: '',
                show: false,
                list: [],
                loading: false,
                finished: false,
                refreshing: false,
                page: 0,
                size: 20,
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            toDetail(item) {
                this.$router.push({path: '/message-detail', query: {id: item.id}})
            },
            onLoad() {
                if (this.refreshing) {
                    this.list = [];
                    this.refreshing = false;
                }
                this.$axios
                    .get('/user/message_list' + '?page=' + this.page)
                    .then((res) => {
                        this.page = this.page + 1;
                        this.list = this.list.concat(res.data.data);
                        if (res.data.data.length < this.size) {
                            this.finished = true;
                        }
                        this.loading = false;
                    });
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;

                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.page = 0;
                this.onLoad();
            },
        },
    }
</script>
<style scoped="scoped">
    .msg-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #252f46;
        border-radius: 30px;
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }
    .wt-card{
        padding: 15px;
        margin-bottom: 15px;
    }
    .msg-title{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 16px;
    }





</style>